import React from 'react';

function Precipitation() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
			<path d="M13.9498 17.7996C12.7347 19.0148 10.7646 19.0148 9.54946 17.7996C8.33433 16.5845 8.33433 14.6144 9.54946 13.3993C10.7646 12.1841 15.875 11.4741 15.875 11.4741C15.875 11.4741 15.165 16.5845 13.9498 17.7996Z" fill="#047FFD"/>
			<path d="M7.34924 11.1995C6.13411 12.4147 4.164 12.4147 2.94887 11.1995C1.73374 9.98442 1.73374 8.0143 2.94887 6.79918C4.164 5.58405 9.2744 4.87401 9.2744 4.87401C9.2744 4.87401 8.56437 9.98442 7.34924 11.1995Z" fill="#047FFD"/>
			<path d="M15.8746 9.27425C14.6595 10.4894 12.6894 10.4894 11.4743 9.27425C10.2591 8.05912 10.2591 6.08901 11.4743 4.87388C12.6894 3.65875 17.7998 2.94872 17.7998 2.94872C17.7998 2.94872 17.0898 8.05912 15.8746 9.27425Z" fill="#047FFD"/>
		</svg>
	);
}

export default Precipitation;