import React from 'react';

function Location2({ color = '#BFD0E0', ...rest }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...rest}>
			<rect width="5" height="5" rx="1" fill={color}/>
			<rect y="7" width="5" height="5" rx="1" fill={color}/>
			<rect x="7" width="5" height="5" rx="1" fill={color}/>
			<rect x="7" y="7" width="5" height="5" rx="1" fill={color}/>
		</svg>
	);
}

export default Location2;