import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { API_URI } from 'src/constants';
import { useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import Helper from 'src/front/helpers/Helper';
import SearchResults from 'src/front/components/Portal';
import useLang from 'src/hooks/useLang';
import { Cross } from 'src/front/components/icons';
import useStyles from 'src/hooks/useStyles';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import ResizeSensible from 'src/front/components/ResizeSensible';

import styles from './style.css';

const helper = {
  offeset (e, p) {
    let top = e.offsetTop;
    let left = e.offsetLeft;
    let parent = e.offsetParent;

    while (parent && parent !== p) {
      top += parent.offsetTop;
      left += parent.offsetLeft
      parent = parent.offsetParent;
    }

    return { left, top };
  }
};

const typesOrder = (types) => {
  const order = ['countries', 'regions', 'cities'];
  return types.split(',').sort((a, b) => order.indexOf(a) - order.indexOf(b)).join(',');
};

const setGlobalCookie = (() => {
  const queue = [];

  const execute = async () => {
    await Helper.setGlobalCookie(...queue[0]);
    queue.splice(0, 1);
    if (queue.length) setTimeout(execute);
  };

  return (...params) => {
    if (queue.length < 2) queue.push(params);
    else queue[1] = params;

    if (queue.length === 1) execute();
  };
})();

const loadLocations = (search, types, language, params) => {
  return new Promise((resolve) => {
    axios
      .get(`${API_URI}/search-locations/${encodeURIComponent(search)}/${types}/${language}${params ? '?' + Object.keys(params).map((p) => encodeURIComponent(p) + '=' + encodeURIComponent([Array, Object].includes(params[p].constructor) ? JSON.stringify(params[p]) : params[p])).join('&') : ''}`, { withCredentials: true })
      .then(({ data }) => resolve(data))
      .catch([]);
  });
};

const RememberedLocations = (() => {
  const rememberedLocations = {};
  const updatingLocations = {};

  return {
    get(type, params) {
      if (type in rememberedLocations) {
        for (const value of rememberedLocations[type]) {
          if (Helper.compare(params, value.params)) {
            return value;
          }
        }
      }

      return null;
    },

    set(type, value) {
      if (!(type in rememberedLocations)) rememberedLocations[type] = [value];
      else {
        for (let i = 0; i < rememberedLocations[type].length; i++) {
          if (Helper.compare(value.params, rememberedLocations[type][i].params)) {
            rememberedLocations[type][i].hooks.push(...value.hooks);
            rememberedLocations[type][i].hooks = rememberedLocations[type][i].hooks.filter((e, i, a) => a.indexOf(e) === i);
            return rememberedLocations[type][i];
          }
        }

        rememberedLocations[type].push(value);
      }

      Object.defineProperty(value, 'locations', {
        get: function() { return this._locations; },
        set: function(locations) {
          if (this._locations && rememberedLocations[type].length > 1 && !(type in updatingLocations)) {
            updatingLocations[type] = this;

            (async function updateRememberedLocations() {
              const cookieName = `searchLocationRememberedLocations:${type}`;
              const cookieLocations = Helper.getCookie(cookieName);

              if (cookieLocations) {
                const loadedLocations = [];

                await Promise.all(rememberedLocations[type].filter((v) => v !== updatingLocations[type]).map(async (v) => {
                  loadedLocations.push([v, (await loadLocations('null', type, v.language, { ...v.params, ids: cookieLocations })).locations]);
                }));

                if (Helper.getCookie(cookieName) === cookieLocations) {
                  for (const loadedLocation of loadedLocations) {
                    loadedLocation[0]._locations = loadedLocation[1];
                    loadedLocation[0].hooks.forEach((hook) => hook.setSearchResults({ ...hook.searchResultsRef.current, locations: Helper.cloneObject(loadedLocation[1]) }));
                  }

                  delete updatingLocations[type];
                } else setTimeout(updateRememberedLocations);
              }
            })();
          } else if (updatingLocations[type]) updatingLocations[type] = this;

          this._locations = locations;
        }
      });

      return value;
    }
  };
})();

export default ({ 
  selectLocation, onChange, onClear, beforeBlur, onBlur, types, params, label = '', clearButton = true, defaultValue, className, style, disabled, 
  usePortal = false, openTo = 'bottom', placeholder, onRememberLocations, searchResultsVariant = 1, onSearchResultChange, onFocus, autoLoadRememberedLocations,
  before, after
}) => {
  useStyles(styles);

  const type = typesOrder(types);
  const cookieName = `searchLocationRememberedLocations:${type}`;
  const rememberedLocations = RememberedLocations.get(type, params);
  const [searchResults, setSearchResults] = useState({ request: '', locations: Helper.cloneObject(rememberedLocations?.locations) || [], searching: false, notFound: false });
  const [searchResultsProps, setSearchResultsProps] = useState(null);
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState('');
  const [target, setTarget] = useState(null);
  const { lang: language, languages } = useSelector((state) => state.language);
  const lang = useLang('SearchLocation');
  const valueRef = useRef(defaultValue !== undefined ? defaultValue : value);
  const fieldRef = useRef(null);
  const searchResultsNodeRef = useRef(null);
  const searchResultsTimer = useRef(null);
  const runBlur = useRef(true);
  const searchResultsRef = useRef(null);
  const targetRef = useRef(null);
  const selectLocationRef = useRef(null);
  const paramsRef = useRef(null);
  const typeRef = useRef(null);
  const cookieNameRef = useRef(null);
  const focusRef = useRef(null);
  const onRememberLocationsRef = useRef(null);
  const isMounted = useIsMountedRef();
  const resultsStyle = {};
  const selectTarget = useRef((ev) => {
    if ((ev.type === 'click' && ev.target !== fieldRef.current.getElementsByTagName('input')[0]) || (ev.type === 'keydown' && [13].includes(ev.keyCode))) {
      let deleteButton = ev.type === 'click' && $(ev.target).closest('.delete-remembered-location')[0];

      if (ev.type === 'click' && deleteButton) {
        ev.preventDefault();

        const result = searchResultsRef.current.locations[deleteButton.getAttribute('data-index')];

        let rememberedValue = (Helper.getCookie(cookieNameRef.current)?.split(',') || []).filter((v) => v && v !== `${result.type}:${result._id}`).join(',');
        setGlobalCookie(languages, cookieNameRef.current, rememberedValue);

        const rememberedLocations = RememberedLocations.get(typeRef.current, paramsRef.current);
        rememberedLocations.locations = rememberedLocations.locations.filter((l) => l._id !== result._id || l.type !== result.type);
        rememberedLocations.hooks.forEach((hook) => hook.setSearchResults({ ...hook.searchResultsRef.current, locations: Helper.cloneObject(rememberedLocations.locations) }));

        if (fieldRef.current) {
          runBlur.current = false;
          fieldRef.current.getElementsByTagName('input')[0].focus();
        }
      } else if (ev.type !== 'click' || $(ev.target).closest('.search-location-results').length) {
        const result = searchResultsRef.current.locations[targetRef.current];
        const rememberedLocations = RememberedLocations.get(typeRef.current, paramsRef.current);

        if (!paramsRef.current || !('country_id' in paramsRef.current)) {
          const cookieValue = Helper.getCookie(cookieNameRef.current);
          let rememberedValue = cookieValue?.split(',') || [];
          rememberedValue.splice(0, 0, `${result.type}:${result._id}`);
          rememberedValue = rememberedValue.filter((e, i, a) => e && a.indexOf(e) === i);
          rememberedValue.splice(10, rememberedValue.length - 10);
          rememberedValue = rememberedValue.join(',');
          if (cookieValue !== rememberedValue) setGlobalCookie(languages, cookieNameRef.current, rememberedValue);

          if (rememberedLocations?.locations) {
            rememberedLocations.locations.splice(0, 0, result);
            rememberedLocations.locations = rememberedLocations.locations.filter((l, i) => !i || l.type != result.type || l._id != result._id);
            rememberedLocations.locations.splice(10, rememberedLocations.locations.length - 10);

            rememberedLocations.hooks.forEach((hook) => hook.searchResultsRef !== searchResultsRef && hook.setSearchResults({ ...hook.searchResultsRef.current, locations: Helper.cloneObject(rememberedLocations.locations) }));
          }
        }

        valueRef.current = '';
        setValue('');
        setFocus(false);
        setTarget(null);
        setSearchResults({ request: '', locations: Helper.cloneObject(rememberedLocations?.locations) || [], searching: false, notFound: false });
        if (onRememberLocationsRef.current) onRememberLocationsRef.current(Helper.cloneObject(rememberedLocations?.locations) || []);
        if (onBlur) onBlur();

        // eslint-disable-next-line eqeqeq
        selectLocationRef.current(paramsRef.current?.allLanguages == 1 ? result : { ...result, name: Helper.getFieldValue(result.name, language), ...result.country ? { country: Helper.getFieldValue(result.country, language) } : {} });

        if (ev.type === 'keydown') {
          runBlur.current = false;
          if (fieldRef.current) fieldRef.current.getElementsByTagName('input')[0].blur();
        }
      } else {
        setFocus(false);
        setTarget(null);
        if (onBlur) onBlur();
      }
    }
  });
  let parentElement;

  searchResultsRef.current = searchResults;
  targetRef.current = target;
  selectLocationRef.current = selectLocation;
  paramsRef.current = params;
  typeRef.current = type;
  cookieNameRef.current = cookieName;
  focusRef.current = focus;
  onRememberLocationsRef.current = onRememberLocations;

  if ((defaultValue !== undefined ? defaultValue : value) !== valueRef.current) {
    valueRef.current = defaultValue !== undefined ? defaultValue : value;
    setTarget(null);
    setSearchResults({ request: '', locations: Helper.cloneObject(RememberedLocations.get(type, params)?.locations) || [], searching: false, notFound: false });
  }

  const handleSearchLocation = (ev) => {
    if (ev.target.value) {
      setSearchResults({ request: ev.target.value, locations: [], searching: true, notFound: false });

      loadLocations(ev.target.value, types, language, params).then((data) => {
        if (data.request === valueRef.current) {
          setTarget(0);
          setSearchResults(data);
        }
      });
    } else {
      setTarget(0);
      setSearchResults({ request: '', locations: Helper.cloneObject(RememberedLocations.get(type, params)?.locations) || [], searching: false, notFound: false });
    }
    valueRef.current = ev.target.value;
    onChange ? onChange(ev.target.value) : setValue(ev.target.value);
    setFocus(true);

    if (searchResultsTimer.current) {
      setSearchResultsProps({ className: 'visible' });
      clearTimeout(searchResultsTimer.current);
      searchResultsTimer.current = null;
    }
  };

  const handleKeyDown = (ev) => {
    // eslint-disable-next-line default-case
    switch (ev.keyCode) {
      case 9: {
        setTarget(null);
        setFocus(false);
        break;
      }

      case 38: {
        if (searchResults.locations.length) {
          setTarget(target !== null && target - 1 >= 0 ? target - 1 : searchResults.locations.length - 1);
        }
        break;
      }

      case 40: {
        if (searchResults.locations.length) {
          setTarget(target !== null && target + 1 < searchResults.locations.length ? target + 1 : 0);
        }
      }
    }
  };

  const handleFocus = () => { setFocus(true); if (searchResultsRef.current.locations.length) setTarget(0); if (onFocus) onFocus(); };

  useEffect(() => {
    if (target !== null && !selectTarget.current.attached) {
      document.addEventListener('click', selectTarget.current);
      document.addEventListener('keydown', selectTarget.current);
  
      selectTarget.current.attached = true;
    } else if (target === null && selectTarget.current.attached) {
      document.removeEventListener('click', selectTarget.current);
      document.removeEventListener('keydown', selectTarget.current);
  
      delete selectTarget.current.attached;
    }
  }, [target]);

  useEffect(() => {
    const hook = {
      searchResultsRef,
      setSearchResults: (searchResults) => {
        if (onRememberLocationsRef.current) onRememberLocationsRef.current(Helper.cloneObject(searchResults?.locations) || []);

        if (!searchResultsRef.current.request) {
          setSearchResults(searchResults);
          if (focusRef.current) setTarget(0);
        }
      }
    };
    let rememberedLocations = RememberedLocations.get(type, params);

    if (!rememberedLocations) {
      rememberedLocations = RememberedLocations.set(type, { hooks: [hook], params, language });
    } else {
      rememberedLocations.hooks.push(hook);

      if (!searchResultsRef.current.request && rememberedLocations.locations && !Helper.compare(searchResultsRef.current.locations, rememberedLocations.locations)) {
        setSearchResults({ ...searchResultsRef.current, locations: Helper.cloneObject(rememberedLocations.locations) || [] });
      }

      if (rememberedLocations.locations && onRememberLocationsRef.current) onRememberLocationsRef.current(Helper.cloneObject(rememberedLocations.locations));
    }

    if (autoLoadRememberedLocations && !rememberedLocations.locations) {
      if (!params || !('country_id' in params)) {
        (async function loadRememberedLocations() {
          const cookieLocations = Helper.getCookie(cookieName);

          if (cookieLocations) {
            const loadedLocations = await loadLocations('null', types, language, { ...params, ids: cookieLocations });
            const newCookieLocations = Helper.getCookie(cookieName);

            if (newCookieLocations === cookieLocations) {
              rememberedLocations.locations = loadedLocations.locations;
              rememberedLocations.hooks.forEach((hook) => hook.setSearchResults({ ...hook.searchResultsRef.current, locations: Helper.cloneObject(loadedLocations.locations) }));
            } else setTimeout(loadRememberedLocations);
          } else rememberedLocations.locations = [];
        })();
      } else rememberedLocations.locations = [];
    }

    return () => {
      rememberedLocations.hooks = rememberedLocations.hooks.filter((h) => h !== hook);
    };
  }, [type, JSON.stringify(params)]);

  const existsResult = !!(searchResults.searching || searchResults.notFound || searchResults.locations.length);

  useEffect(() => {
    if (focus) {
      const rememberedLocations = RememberedLocations.get(type, params);

      if (!rememberedLocations.locations) {
        if (!params || !('country_id' in params)) {
          (async function loadRememberedLocations() {
            const cookieLocations = Helper.getCookie(cookieName);

            if (cookieLocations) {
              const loadedLocations = await loadLocations('null', types, language, { ...params, ids: cookieLocations });
              const newCookieLocations = Helper.getCookie(cookieName);

              if (newCookieLocations === cookieLocations) {
                rememberedLocations.locations = loadedLocations.locations;
                rememberedLocations.hooks.forEach((hook) => hook.setSearchResults({ ...hook.searchResultsRef.current, locations: Helper.cloneObject(loadedLocations.locations) }));
              } else setTimeout(loadRememberedLocations);
            } else rememberedLocations.locations = [];
          })();
        } else rememberedLocations.locations = [];
      }

      if (searchResultsTimer.current) clearTimeout(searchResultsTimer.current);

      if (existsResult || rememberedLocations.locations) {
        if (existsResult && searchResultsNodeRef.current && !searchResultsProps && !searchResults.searching && !searchResults.notFound) {
          const props = { className: 'visible' };

          switch (searchResultsVariant) {
            case 2:
              props.style = { height: `${searchResultsNodeRef.current.querySelector(':scope .search-location-results-value').getBoundingClientRect().height}px` };

              searchResultsTimer.current = setTimeout(() => {
                setSearchResultsProps({ className: 'visible' });
                searchResultsTimer.current = null;
              }, 300);
              break;
          }

          setSearchResultsProps(props);
        } else if (isMounted.current) setSearchResultsProps({ className: 'visible' });
      }
    } else {
      if (existsResult && searchResultsNodeRef.current) {
        if (searchResultsTimer.current) clearTimeout(searchResultsTimer.current);

        const props = {};

        switch (searchResultsVariant) {
          case 2:
            const wrapper = searchResultsNodeRef.current.querySelector(':scope .search-location-results-wrapper');
            wrapper.style.height = `${searchResultsNodeRef.current.querySelector(':scope .search-location-results-value').getBoundingClientRect().height}px`;
            window.getComputedStyle(wrapper).getPropertyValue('height');

            props.style = { height: `0px` };
            break;
        }

        searchResultsTimer.current = setTimeout(() => {
          setSearchResultsProps(null);
          searchResultsTimer.current = null;
        }, 300);

        setSearchResultsProps(props);
      } else if (isMounted.current) setSearchResultsProps(null);
    }
  }, [focus, existsResult]);

  useEffect(() => {
    if (onSearchResultChange && rememberedLocations?.locations) {
      if (!existsResult || !focus) onSearchResultChange(null, focus);
      else onSearchResultChange(searchResultsNodeRef.current, focus);
    }
  }, [
    focus,
    searchResults.searching,
    searchResults.notFound,
    searchResults.locations.map((l) => `${l.flag},${Helper.getFieldValue(l.name, l.searchLanguage)},${l.country ? Helper.getFieldValue(l.country, l.searchLanguage) : ''}`).join('|'),
    !!rememberedLocations?.locations
  ]);

  useEffect(() => {
    if (document.activeElement === fieldRef.current?.querySelector('input')) handleFocus();

    return () => {
      document.removeEventListener('click', selectTarget.current);
      document.removeEventListener('keydown', selectTarget.current);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      delete selectTarget.current.attached;
    };
  }, []);

  if (fieldRef.current && usePortal) {
    parentElement = usePortal === true ? document.body: (typeof usePortal == 'string' ? $(usePortal)[0] : usePortal);

    const offset = helper.offeset(fieldRef.current, parentElement);

    for (let element = fieldRef.current; element && element !== document;) {
      const styles = window.getComputedStyle(element);

      if (styles.getPropertyValue('position') === 'fixed') {
        resultsStyle.position = 'fixed';
        break;
      }

      element = element.parentNode;
    }

    resultsStyle.top = `${offset.top + fieldRef.current.offsetHeight + 5}px`;
    resultsStyle.left = `${offset.left}px`;
    resultsStyle.minWidth = `${fieldRef.current.offsetWidth / 2}px`;
    resultsStyle.maxWidth = `${document.body.clientWidth - offset.left}px`;
  }

  let searchResultContent;
  if (searchResults.searching) searchResultContent = <div onMouseOver={(ev) => runBlur.current = ev.target.tagName.toLowerCase() !== 'constant-translate-control'} onMouseLeave={() => runBlur.current = true} className="search-location-results-value search-location-searching">{lang.t('Searching...')}</div>;
  else if (searchResults.notFound) searchResultContent = <div onMouseOver={(ev) => runBlur.current = ev.target.tagName.toLowerCase() !== 'constant-translate-control'} onMouseLeave={() => runBlur.current = true} className="search-location-results-value search-location-not-found">{lang.t('Nothing found')}</div>;
  else searchResultContent = <ul className="search-location-results-value scroll">
    {
      searchResults.locations.map((r, n) => {
        const className = [];

        if (target === n) className.push('target');
        if (!valueRef.current) className.push('with-delete-button');

        return <li
          {...className.length ? { className: className.join(' ') } : {}}
          onMouseEnter={() => runBlur.current = false}
          onMouseMove={() => setTarget(n)}
          onMouseLeave={() => runBlur.current = true}
        >
          <span
            className="text-3 color-20"
            {...r.flag ? { style: { [`padding${lang.currentLanguage.direction === 'rtl' ? 'Right' : 'Left'}`]: '30px', background: `url(${Helper.getFileUrl('country', r.flag)}) ${lang.currentLanguage.direction === 'rtl' ? 'right ' : ''}0 center / 19px 13px no-repeat` } } : {}}
            dangerouslySetInnerHTML={{
              __html: searchResults.request ?
                      Helper.getFieldValue(r.name, r.searchLanguage).replace(new RegExp(`(${searchResults.request.split(/(?=.)/).join("[-\\s']*")})`, 'gi'), '<span>$1</span>'):
                      Helper.getFieldValue(r.name, r.searchLanguage)
            }}
          />
          <span className="text-3 color-8">{r.country ? ' ' + Helper.getFieldValue(r.country, r.searchLanguage) : ''}</span>
          {
            !valueRef.current &&
            <a className="delete-remembered-location" data-index={n} href="#">
              <Cross color="#5c6670"/>
            </a>
          }
        </li>
      })
    }
  </ul>;

  return (
    <div className={`search-location${focus ? ' focused' : ''}${className ? ' ' + className : ''}`} {...style ? { style } : {}}>
      {before}
      {clearButton && <button onClick={() => { setValue(''); setTarget(null); setSearchResults({ request: '', locations: Helper.cloneObject(RememberedLocations.get(type, params)?.locations) || [], searching: false, notFound: false }); if (onClear) onClear(); }}></button>}
      {typeof document == 'object' && (searchResults.searching || searchResults.notFound || searchResults.locations.length) && (focus || searchResultsProps) ?
        (usePortal ?
          (Object.keys(resultsStyle).length ? <SearchResults parent={parentElement}>
            <div ref={searchResultsNodeRef} style={resultsStyle} className={`search-location-results portal search-results-variant-${searchResultsVariant}${searchResultsProps?.className ? ' ' + searchResultsProps.className : ''}`}>
              <div {...searchResultsProps?.style ? { style: searchResultsProps.style } : {}} className="search-location-results-wrapper">
                {searchResultContent}
              </div>
            </div>
          </SearchResults> : '') :
          <div ref={searchResultsNodeRef} style={resultsStyle} className={`search-location-results search-results-variant-${searchResultsVariant} open-to-${openTo}${searchResultsProps?.className ? ' ' + searchResultsProps.className : ''}`}>
            <div {...searchResultsProps?.style ? { style: searchResultsProps.style } : {}} className="search-location-results-wrapper">
              {searchResultContent}
            </div>
          </div>
        ) : ''}
      <TextField
        translate={label ? 'yes' : 'no'}
        ref={fieldRef}
        label={label}
        fullWidth
        onFocus={handleFocus}
        onBlur={(ev) => { if (runBlur.current) { if (beforeBlur) beforeBlur(ev); setTarget(null); setFocus(false); if (onBlur) onBlur(); } else runBlur.current = true; }}
        onChange={handleSearchLocation}
        onKeyDown={handleKeyDown}
        value={defaultValue !== undefined ? defaultValue : value}
        variant="outlined"
        disabled={disabled}
        placeholder={placeholder || lang.t('Search location...', null, true)}
      />
      {after}
    </div>
  );
};