import React from 'react';

function Location({ color1 = '#5C6684', color2 = '#F6FCFF', ...rest }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none" {...rest}>
			<path d="M10 5C10 10 5 15.5 5 15.5C5 15.5 0 10 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5Z" fill={color1}/>
			<path d="M6.5 5C6.5 5.82843 5.82843 6.5 5 6.5C4.17157 6.5 3.5 5.82843 3.5 5C3.5 4.17157 4.17157 3.5 5 3.5C5.82843 3.5 6.5 4.17157 6.5 5Z" fill={color2}/>
		</svg>
	);
}

export default Location;