import React, { useEffect, useRef } from 'react';
import { IS_SERVER } from 'src/constants';

const ids = [];

const serverCalculations = () => `{exec}
	if (!('components' in static_data)) static_data.components = {};
	if (!('InteractiveMap' in static_data.components)) static_data.components.InteractiveMap = 0;
	else static_data.components.InteractiveMap++;
{/exec}`;

function InteractiveMap() {
	const id = useRef(null);

	if (!IS_SERVER && id.current === null) {
		id.current = 0;
		while (ids.includes(id.current)) id.current++;
		ids.push(id.current);
	}

	useEffect(() => {
		return () => {
			const index = ids.indexOf(id.current);
			if (index >= 0) ids.splice(index, 1);
		};
	}, []);
	return (
		<svg width="27" height="33" viewBox="0 0 27 33" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0.153809 10.8624L6.30765 6.15381V27.6923L0.153809 32.2842V10.8624Z" fill={`url(#InteractiveMap0_${IS_SERVER ? '{print static_data.components.InteractiveMap}' : id.current})`}/>
			<path d="M13.2308 10.8624L20.1538 6.15381V27.6923L13.2308 32.2842V10.8624Z" fill={`url(#InteractiveMap1_${IS_SERVER ? '{print static_data.components.InteractiveMap}' : id.current})`}/>
			<path d="M13.2308 10.8624L6.30765 6.15381V27.6923L13.2308 32.2842V10.8624Z" fill={`url(#InteractiveMap2_${IS_SERVER ? '{print static_data.components.InteractiveMap}' : id.current})`}/>
			<path d="M26.3077 10.8859L20.1538 6.15381V27.6923L26.3077 32.3077V10.8859Z" fill={`url(#InteractiveMap3_${IS_SERVER ? '{print static_data.components.InteractiveMap}' : id.current})`}/>
			<path d="M6.30765 6.15381L0.153809 10.8624V32.2842L6.30765 27.6923V6.15381Z" fill={`url(#InteractiveMap4_${IS_SERVER ? '{print static_data.components.InteractiveMap}' : id.current})`}/>
			<path d="M20.1538 6.15381L13.2308 10.8624V32.2842L20.1538 27.6923V6.15381Z" fill={`url(#InteractiveMap5_${IS_SERVER ? '{print static_data.components.InteractiveMap}' : id.current})`}/>
			<path d="M22 5C22 10 17 15.5 17 15.5C17 15.5 12 10 12 5C12 2.23858 14.2386 0 17 0C19.7614 0 22 2.23858 22 5Z" fill="#F65E5D"/>
			<path d="M18.5 5C18.5 5.82843 17.8284 6.5 17 6.5C16.1716 6.5 15.5 5.82843 15.5 5C15.5 4.17157 16.1716 3.5 17 3.5C17.8284 3.5 18.5 4.17157 18.5 5Z" fill="#F6FCFF"/>
			<defs>
				<linearGradient id={`InteractiveMap0_${IS_SERVER ? '{print static_data.components.InteractiveMap}' : id.current}`} x1="13.1744" y1="6.15381" x2="13.1744" y2="32.4106" gradientUnits="userSpaceOnUse">
					<stop stop-color="#FF961B"/>
					<stop offset="1" stop-color="#FFCD1E"/>
				</linearGradient>
				<linearGradient id={`InteractiveMap1_${IS_SERVER ? '{print static_data.components.InteractiveMap}' : id.current}`} x1="13.1744" y1="6.15381" x2="13.1744" y2="32.4106" gradientUnits="userSpaceOnUse">
					<stop stop-color="#FF961B"/>
					<stop offset="1" stop-color="#FFCD1E"/>
				</linearGradient>
				<linearGradient id={`InteractiveMap2_${IS_SERVER ? '{print static_data.components.InteractiveMap}' : id.current}`} x1="13.1744" y1="6.15381" x2="13.1744" y2="32.4106" gradientUnits="userSpaceOnUse">
					<stop stop-color="#FF961B"/>
					<stop offset="1" stop-color="#FFCD1E"/>
				</linearGradient>
				<linearGradient id={`InteractiveMap3_${IS_SERVER ? '{print static_data.components.InteractiveMap}' : id.current}`} x1="13.1744" y1="6.15381" x2="13.1744" y2="32.4106" gradientUnits="userSpaceOnUse">
					<stop stop-color="#FF961B"/>
					<stop offset="1" stop-color="#FFCD1E"/>
				</linearGradient>
				<linearGradient id={`InteractiveMap4_${IS_SERVER ? '{print static_data.components.InteractiveMap}' : id.current}`} x1="13.2307" y1="33.0769" x2="13.2307" y2="6.92304" gradientUnits="userSpaceOnUse">
					<stop stop-color="#FF7A30"/>
					<stop offset="1" stop-color="#FFCD1E"/>
				</linearGradient>
				<linearGradient id={`InteractiveMap5_${IS_SERVER ? '{print static_data.components.InteractiveMap}' : id.current}`} x1="13.2307" y1="33.0769" x2="13.2307" y2="6.92304" gradientUnits="userSpaceOnUse">
					<stop stop-color="#FF7A30"/>
					<stop offset="1" stop-color="#FFCD1E"/>
				</linearGradient>
			</defs>
		</svg>
	);
}

export default InteractiveMap;